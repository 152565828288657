import React, { useEffect, useState, useRef } from "react";
import { Link, Redirect, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from "axios";
import authAction from "../../../redux/auth/actions";
import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { logigloUrl } from "../../Config/index";
import Navbar from "../../Navbar";
import LandingPageAnimation from "../../LandingPageComponents/LandingPageAnimation";
import WhatWeDoSection from "../../LandingPageComponents/WhatWeDoSection";
import WhyUsSection from "../../LandingPageComponents/WhyUsSection";
import ConnectingGlobeSection from "../../LandingPageComponents/ConnectingGlobeSection";
import TestimonialSection from "../../LandingPageComponents/TestimonialSection";
import Carousel from "../../LandingPageComponents/Carousel";
import WhatWeDoSectionOld from "../../LandingPageComponents/WhatWeDoSectionOld";
import RecentPostsSection from "../../LandingPageComponents/RecentPostsSectionOld";
import RecentPostsCard from "../../LandingPageComponents/RecentPostsSection";
import LandingpageContactUs from "../../LandingPageComponents/LandingpageContactUs";
import Footer from "../../LandingPageComponents/footer";
import LandingPageVideo from "../landingPageVideo";
import "aos/dist/aos.css";
import { useLocation } from "react-router-dom";
import ScrollAnimation from "../../scrollAnimation";
import actions from "../../../redux/auth/actions";
import useAOS from "../../scrollAnimation";
// import MessageIcon from '../../../assests/messages'

const LandingPage = () => {
  const location = useLocation();
  const [carouselData, setCarouselData] = useState([]);
  const [whatWeDoSection, setwhatWeDoSection] = useState([]);
  const token = useSelector((state) => state.Auth.idToken);
  const accountId = useSelector((state) => state.Auth.accountId);
  const account_name = useSelector((state) => state.Dashboard.name);
  const testingRedux = useSelector((state) => state);
  // console.log(testingRedux, "testingRedux");
  // const accountType = useSelector((state) => state.Auth.accountType || "defaultType");
  const accountType = new URLSearchParams(location.search).get("accountType");
  const [carouselSectionStatus, setCarouselSectionStatus] = useState([]);
  const [whyUsSectionStatus, setWhyUsSectionStatus] = useState(null);
  const [whatWeDoSectionStatus, setWhatWeDoSectionStatus] = useState(null);
  const [connectingGlobeSectionStatus, setConnectingGlobeSectionStatus] =
    useState(null);
  const [contactUsSectionStatus, setContactUsSectionStatus] = useState(null);
  const [testimonialStatus, setTestimonialStatus] = useState(null);

  const form = useRef();
  const [contact, setContact] = useState("");
  const [contactError, setContactError] = useState("");
  // console.log("token", token);
  // console.log("accountId", accountId);
  // console.log("accountType", accountType);
  const history = useHistory();
  const { logout } = authAction;

  const dispatch = useDispatch();

  useEffect(() => {
    axios
      .get(`${logigloUrl}/getCarouselData`)
      .then((response) => {
        const responseData = response.data[0];
        setCarouselSectionStatus(responseData.render_status);
        // console.log(responseData, "ppopop");
      })
      .catch((error) => {
        console.error("Error fetching carousel data:", error);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${logigloUrl}/getWhyUsSectionData`)
      .then((response) => {
        const responseData = response.data[0];

        setWhyUsSectionStatus(responseData.render_status);

        // console.log(responseData);
      })
      .catch((error) => {
        console.error("Error fetching why us section data:", error);
      });
  }, []);

  // Fetch whatWeDoSection data from the backend when the component mounts
  useEffect(() => {
    axios
      .get(`${logigloUrl}/whatWeDoSection`)
      .then((response) => {
        const responseData = response.data;
        const whatWeDoSectiontextDetails = response.data.whatWeDoSection[0];

        setWhatWeDoSectionStatus(whatWeDoSectiontextDetails.render_status);

        // console.log(responseData.);
      })
      .catch((error) => {
        console.error("Error fetching whatWeDoSection data:", error);
      });
  }, []);

  // Fetch connectingGlobeSection data from the backend when the component mounts
  useEffect(() => {
    axios
      .get(`${logigloUrl}/connectingGlobeSection`)
      .then((response) => {
        setConnectingGlobeSectionStatus(
          response.data.connectingSection[0].render_status
        );
      })
      .catch((error) => {
        console.error("Error fetching careersSection data:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch whatWeDoSection data from the backend when the component mounts
    axios
      .get(`${logigloUrl}/testimonialSection`)
      .then((response) => {
        // console.log(
        //   response.data.testimonialSection[0].render_status,
        //   "testtttt"
        // );

        setTestimonialStatus(response.data.testimonialSection[0].render_status);
        // console.log(organizedArray);
      })
      .catch((error) => {
        console.error("Error fetching careersSection data:", error);
      });
  }, []);

  // Fetch ContactUs data from the backend when the component mounts
  useEffect(() => {
    axios
      .get(`${logigloUrl}/contactUsSection`)
      .then((response) => {
        const responseData = response.data[0];

        setContactUsSectionStatus(responseData.render_status);

        // console.log(responseData, "conarctcaro");
      })
      .catch((error) => {
        console.error("Error fetching carousel data:", error);
      });
  }, []);

  // console.log(carouselSectionStatus, "acacac");

  const logouts = () => {
    dispatch(logout());
    localStorage.removeItem("token");
    history.replace("/");
    logout();
  };

  const validateContact = () => {
    const contactPattern = /^\d{10}$/;

    if (!/^\d+$/.test(contact)) {
      setContactError("Contact number must be numbers only.");
      return false;
    } else if (contact.length !== 10) {
      setContactError("Contact number must be 10 digits.");
      return false;
    } else {
      setContactError("");
      return true;
    }
  };

  return (
    <HelmetProvider>
      <Helmet>
        <title> Logiglo</title>
        <link rel="canonical" href="https://logiglo.com" />
        <meta property="og:title" content="Logiglo" />

        <meta
          property="og:description"
          content="Gateway to the global world of logistics and supply chain management. Discover opportunities, insights, and innovations that drive the industry forward, helping you grow your business and career with cutting-edge solutions and expert advice."
        />

        <meta
          property="og:image"
          content="https://opengraph.b-cdn.net/production/images/f8ba140a-981c-4421-a09e-ebae95b0534f.png?token=FXByuwPXqDXbCAr2fdnElBC4FiDHbW6vOC8r-8b_Kg0&height=675&width=1200&expires=33259546757"
        />
        <meta property="og:url" content="https://logiglo.com" />
        <meta property="og:type" content="website" />
      </Helmet>

      <div className="landing-page-outer-container">
        <LandingPageVideo />

        {/* <LandingPageAnimation /> */}

        <div className="landing-page-con">
          {/* Navbar Section */}

          <div className="home-page-con">
            <Navbar />
            {/* <TopCarousel /> */}

            {/* Carousel Section */}
            {/* {carouselSectionStatus === 1 ? ( <Carousel />) : (null)} */}

            {/* What we do Section  Old*/}

            {/* <WhatWeDoSectionOld  /> */}

            {/* What we do Section  */}

            {whatWeDoSectionStatus === 1 ? <WhatWeDoSection /> : null}

            <RecentPostsCard />

            {whyUsSectionStatus === 1 ? <WhyUsSection /> : null}

            {connectingGlobeSectionStatus === 1 ? (
              <ConnectingGlobeSection />
            ) : null}

            {testimonialStatus === 1 ? <TestimonialSection /> : null}

            {contactUsSectionStatus === 1 ? <LandingpageContactUs /> : null}

            {/* Footer Section */}
          </div>
        </div>
        <Footer />
      </div>
    </HelmetProvider>
  );
};

export default LandingPage;
